html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
    background-color: #21252d; /* Set background color for entire page */
    color: #d4d4d4; /* Ensure text color is consistent */
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #21252d;
    padding: 30px;
}

.app-header {
    padding: 10px;
    text-align: center;
    background-color: #21252d;
    color: #d4d4d4;
}

.main-content {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
    width: 100%;
}

.code-editor-container,
.code-output-container,
.code-assembly-container,
.private-input-container,
.public-input-container {
    padding: 5px;
    background-color: #21252d; /* Match the dark theme */
    border-radius: 4px;
    box-shadow: none;
    box-sizing: border-box;
}

.result-textarea {
    padding: 5px;
    width: 100%;
    max-width: 100%;
    background-color: #21252d;
    color: #ffffff;
    font-family: monospace;
    overflow: auto;
    box-sizing: border-box;
}

.code-editor {
    border-radius: 4px;
    border: 1px solid #444;
    height: 100%;
    width: 100%;
}
